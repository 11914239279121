import React, {Component} from "react";
import NumberFormat from "react-number-format";
const moreRow = (per, tip = false) => (
  <tr className="showMoreRow" key={per}>
    {[1, 2, 3, 4, 5, 6, 7].map((i) => (
      <td key={per + i.toString()} style={{ textAlign: "center", writingMode: "vertical-lr", verticalAlign: "middle" }}>
        ...
      </td>
    ))}
  </tr>
);

class ResultsTableBody extends Component {
  render() {
    //TODO: Change the state calculation to be made at the parent.. that fixes everything
    var rows = [];
    //Set up the initial values
    let per = 0;
    let conditions = ["SAVING", "RETIRED"];
    let pensionYears = this.props.pensionYears;
    let showLess = this.props.showLess;
    let decimals = this.props.decimalScale;
    let data = this.props.data;
    let prevData = this.props.prevData;
    
    if (!data) return <></>;
    if (!prevData) prevData = data;
    for (let condition of conditions) {
      const end = condition === "SAVING" ? this.props.saveYears : pensionYears;
      if(condition!=="SAVING"){
        rows.push(
          <tr key={"Separator"+per.toString()}>
            <td colSpan="10" style={{textAlign:"center"}}>Changing condition from saving to retired</td>
          </tr>
        );
      }
     
      for (let i = 0; i < end; i++) {
        if(!(per in data))
          continue;
        const inData = data[per];
        const inPrevData = prevData[per] ?? data[per];
        per++;

        if (i <= 2 || i >= end - 2 || !showLess) {
          
          let realIncrease = inData.realIncrease;
          const changedSavings = inData.savings !== inPrevData.savings;
          const changedbb = inData.bb !== inPrevData.bb;
          const changedeb = inData.eb !== inPrevData.eb;
          const changedreturn = inData.returnBB !== inPrevData.returnBB;
          if (changedSavings) console.log("Changed!");
          const savingsComponent =
            inData.savings !== 0 ? <NumberFormat key={per + "4"} displayType="text" thousandSeparator={true} value={inData.savings} decimalScale={decimals} fixedDecimalScale={true} /> : <span></span>;
          const pensionComponent =
            inData.pension !== 0 ? <NumberFormat key={per + "5"} displayType="text" thousandSeparator={true} value={inData.pension} decimalScale={decimals} fixedDecimalScale={true} /> : <span></span>;

          rows.push(
            <tr key={per}>
              <td style={{ textAlign: "center" }}>
                <NumberFormat key={per + "1"} displayType="text" thousandSeparator={true} value={per} decimalScale={0} />
              </td>
              <td>
                <div className={changedbb ? "animate" : ""}>
                  <NumberFormat key={per + "2"} displayType="text" thousandSeparator={true} value={inData.bb} decimalScale={decimals} fixedDecimalScale={true} />
                </div>
              </td>
              <td>
                <div className={changedreturn ? "animate" : ""}>
                  <NumberFormat key={per + "3"} displayType="text" thousandSeparator={true} value={inData.returnBB} decimalScale={decimals} fixedDecimalScale={true} />
                </div>
              </td>
              <td data-tip={realIncrease>1?("Increased "+((realIncrease-1)*100).toFixed(2).toString()+"% compared to previous saved amount."):""} >
                <div className={changedSavings ? "animate" : ""}>{savingsComponent}</div>
              </td>
              <td>{pensionComponent}</td>
              <td>
                <div className={changedeb ? "animate" : ""}>
                  <NumberFormat key={per + "6"} displayType="text" thousandSeparator={true} value={inData.eb + 0.005} decimalScale={decimals} fixedDecimalScale={true} />
                </div>
              </td>
              <td key={per + "7"}>{inData.condition}</td>
            </tr>
          );
        }
        if (i === 3 && showLess) {
          rows.push(moreRow(condition + per.toString()));
          rows.push(moreRow(condition + "mirror" + per.toString()));
        }
      }
    }
    if (this.props.isPerpetual) {
      //rows.push(continuesRow("If fund is conserved , this pension can continue forever"));
    }
    return <tbody>{rows}</tbody>;
  }
}
export default ResultsTableBody;
