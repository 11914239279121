import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {  Col, Row } from "reactstrap";
import Customizable from "./CustomizableComponent";
function About() {
  return (
    <div className="RowContent">
      <Col md={12} className="card-style">
        <Row md={12} className="card-style-padding">
          <Col md={3} style={{ padding: 0 }}>
            <h2 className="h2-custom">
              <Customizable name="HeaderAbout" />
            </h2>
           
            <img
              className="framed-image"
              src="assets\images\growing.jpg"
              alt="finance"
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={9} className="text-left" style={{ fontSize: "1rem", paddingLeft:"1rem"}}>
            <Customizable name="About" editable={false} />
          </Col>
        </Row>
      </Col>
    </div>
  );
}
export default About;
