import { createStore, combineReducers, applyMiddleware } from 'redux'
import { InitialSimulationData} from './forms'
import { createForms } from 'react-redux-form'
import thunk from 'redux-thunk'
import logger from 'redux-logger'


export const ConfigureStore = () => {
	const store = createStore(
		combineReducers({
			...createForms({
				simulationdata: InitialSimulationData
			})
		}),
		applyMiddleware(thunk,logger)
	);
	return store;
}