import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Customizable from "./CustomizableComponent";
export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);
    this.toggleProp = this.toggleProp.bind(this);
    this.state = {
      readMoreDescription: false,
    };
  }

  toggleProp(name) {
    let propVal = !this.state[name];
    this.setState({
      ...this.state,
      [name]: propVal,
    });
  }
  render() {
    return (
      <div>
        <div className="RowContent">
          <Col md={12} className="card-style">
            <Row md={12} className="card-style-padding">
              <Col md={3} style={{ padding: 0 }}>
                <h2 className="h2-custom">Retirement Simulator</h2>
                <img
                  className="framed-image"
                  src="assets\images\trekking.jpg"
                  alt="retirement simulator"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col
                md={9}
                className="text-left p-lg-5"
                style={{ fontSize: "1rem" }}
              >
                <Customizable name="ExplainerText1" editable={false} />
                <div>
                  <button
                    className="btn btn-custom"
                    type="button"
                    onClick={() => this.toggleProp("readMoreDescription")}
                  >
                    Read{" "}
                    {this.state.readMoreDescription ? "less..." : "more..."}
                  </button>
                  <div
                    hidden={!this.state.readMoreDescription}
                    className="card-model-papers"
                  >

                    <Customizable name="ReadMoreText" editable={false} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    );
  }
}
