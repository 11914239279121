import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import RetirementSimulator from './components/RetirementSimulatorComponent';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';
import About from './components/AboutComponent'
import 'font-awesome/css/font-awesome.min.css';
import './custom.css'

export default class App extends Component {
  static displayName = App.name;
  render () {
    const store = ConfigureStore();

    return (
      <Provider store={store}>
        <Layout>
          <Route exact path='/' component={Home} />
          <Route path='/retirementsimulator' component={RetirementSimulator} />
          <Route path='/about' component={About} />
        </Layout>
      </Provider>
    );
  }
}
