import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import ReactHtmlParser from "react-html-parser";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Customizable(props) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [loaded, setLoaded] = useState(false);
  const [name] = useState(props.name);
  const [value, setValue] = useState("");
  const [editable, setEditable] = useState(props.editable);
  // const [editable] = useState(props.editable);
  const { convertFromHTML, ContentState } = require("draft-js");
  const reloadContent = () => {
    fetch("/PageContent?Name=" + name, {
      method: "GET",
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        setValue(data);
        setLoaded(true);
        const blocksFromHTML = convertFromHTML(data);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(() => EditorState.createWithContent(state));
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    //Initial load of the page content value by name
    reloadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Updating the value if there is a change in the editor state;
    if (loaded) {
      const newValue = stateToHTML(editorState.getCurrentContent());
      setValue(newValue);

      fetch("/PageContent", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Name: name,
          Value: newValue,
        }),
      })
        .then((response) => {
          console.log("Saved!", response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);

  return (
    <div
      onDoubleClick={() => {
        setEditable(!editable);
        setLoaded(false);
        reloadContent();
      }}
    >
      {(loaded && ! editable) && <span>{ReactHtmlParser(value)}</span>}
      {!loaded && <span>Loading ...</span>}

      {editable && (
        <div className="container">
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={setEditorState}
          />
        </div>
      )}
    </div>
  );
}
export default Customizable;
