//Financial Functions

/***********************************************
*              Present Value                  *
* pv = fv / (1 + (rate / freq))^periods       *
* pv = Present Value                          *
* fv = Future Value                           *
* rate = interest rate (expressed as %)       *
* freq = compounding frequency                *
* periods = number of periods until maturity  *
***********************************************/
export const presentValue = (fv, freq, rate, periods) => {
	return (fv / Math.pow((1 + (rate / 100 / freq)), periods));
}


export const calcAmortPrincipal = (pymt, freq, rate, periods) =>{
	rate = rate / 100 / freq;
	return (pymt * (1 - (1 / Math.pow(1 + rate, periods))) / rate);
}

/***********************************************
	*                 Annuity                     *
	* a = fv / (((1 + r / c)^n) - 1) / (r/c)      *
	* fv = future value                           *
	* r = interest rate                           *
	* c = compounding frequency                   *
	* n = total number of periods                 *
***********************************************/
export const annuity = (fv, freq, rate, periods) => {
	rate = rate / 100 / freq;
	return (fv / ((Math.pow(1 + rate, periods) - 1)) * rate);
}

/************************************************
*            Annualized Return                 *
* r = (fv - pv) / pv / years                   *
* fv = future value                            *
* pv = present value                           *
* years = term of loan in years                *
************************************************/
export const annualizedReturn = (fv, pv, years) => {
	return Math.pow((fv / pv), (1 / years)) - 1;
}
