import React from "react";
import { Label, Col, Row } from 'reactstrap'
import NumberFormat from 'react-number-format';

function SimulationParameterComponent(props) {
    let param = props.param;
    if(param == null)
        return <React.Fragment></React.Fragment>
    let classNames = ["form-control"];
    let rowClassNames = ["form-group"];
    let hidden = false;
    let decimals = param.f ? param.f : props.viewDecimals ? 2 : 0;
    if (param.rol) {
        return (<React.Fragment></React.Fragment>);
    }
    rowClassNames.push(hidden ? "fadeOut" : "fadeIn");

    return (

        <Row key={"rowparam" + param.name} className={rowClassNames.join(' ')}>
            <Label htmlFor={param.name} md={9} className="simulationParameterLabel">
                {param.description}&nbsp;
                {param.tip ? (<i data-tip={param.tip} className="fa fa-info-circle"></i>) : <></>}
            </Label>

            <Col key={"OutputParamaneter" + param.name} md={3}>
                <NumberFormat
                    thousandSeparator={param.t}
                    prefix={param.p}
                    suffix={param.s}
                    model={"." + param.name}
                    id={param.name}
                    name={param.name}
                    isAllowed={param.rule}
                    autoComplete="off"
                    disabled={param.rol === 'output'}
                    placeholder={param.label}
                    className={classNames.join(' ')}
                    onFocus={() => props.touchValue(param.name)}
                    onValueChange={(values) => {
                        // eslint-disable-next-line
                        const { _ , value } = values;
                        props.setParam(param.name, value)
                    }
                    }
                    decimalScale={decimals}
                    fixedDecimalScale={param.f ? decimals : false}
                    value={props.value}
                />
            </Col>
        </Row>
    );
}
export default SimulationParameterComponent;