export const InitialFeedback = {
	firstname: '',
	lastname: '',
	telnum: '',
	email: '',
	agree: false,
	contactType: 'Tel.',
	message: ''
}

export const InitialSimulationData = {
	saveYears: '',
	pensionYears: '',
	currentAnualIncome: '',
	desiredIncomePercentage: '',
	annualPension: '',
	anualReturnRate: '',
	neededAmount: '',
	neededYearlySave: '',
	percetageYearlySave: ''
}